import React from "react"
import { NumericFormat } from "react-number-format"

export default function NumberFormatterText({
    prefix = "$",
    size = "small",
    // decimalScale = 2, // Send it if required
    ...props
}) {
    return (
        <NumericFormat
            className="text-right"
            displayType="text"
            prefix={prefix}
            thousandSeparator
            // decimalScale={decimalScale}
            size={size}
            fixedDecimalScale
            {...props}
        />
    )
}
