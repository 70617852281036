import axiosSetup, { getIdToken } from "./axiosSetup"

const BACKEND_API =
    process.env.REACT_APP_BASE_URL || "https://qa1-backend.buildbooster.com"
const { getRequest, postRequest, putRequest } = axiosSetup(false, BACKEND_API)

export const getTriposLanes = async (id) => {
    const accessToken = await getIdToken()
    const apiUrl = BACKEND_API + `/payment/tripos/lane/${id}`

    // Create headers
    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", accessToken)

    // Create the fetch request
    return fetch(apiUrl, {
        method: "GET",
        headers: headers,
    })
        .then((resp) => resp.json())
        .catch((error) => {
            console.error("Error:", error.message)
        })
}
export const getTriposLanes1 = (id) =>
    getRequest(`/payment/tripos/lane/${id}`, {
        customHeaders: {
            "Content-Type": "application/json",
        },
    })

export const getTilledConfig = (tenantId) => getRequest(`/payment/get/config`)

export const createTilledPaymentIntent = (tenantId, accountId, requestBody) =>
    postRequest(
        `payment/create/paymentIntent/${tenantId}/${accountId}`,
        requestBody
    )
export const createTilledTransaction = (tenantId, requestBody) =>
    postRequest(`payment/create/transection/${tenantId}`, requestBody)

export const requestPhysicalCardMachine = (tenantId, requestBody) =>
    postRequest(`payment/tripos/sale/txn/${tenantId}`, requestBody)

export const pollTriposSaleTransaction = (tenantId, transactionId) =>
    getRequest(
        `payment/tripos/sale/txn?txnId=${transactionId}&tenantId=${tenantId}`,
        {
            customHeaders: {
                "Content-Type": "application/json",
            },
        }
    )

export const reverseTriposTransaction = (requestBody) =>
    postRequest("payment/tripos/reversal/txn", requestBody)

export const returnTriposTransaction = (requestBody) =>
    postRequest("payment/tripos/return/txn", requestBody)

export const requestRefundToCardMachine = (tenantId, requestBody) =>
    postRequest(`payment/tripos/refund/txn/${tenantId}`, requestBody)

export const pollTriposRefundTransaction = (tenantId, transactionId) =>
    getRequest(
        `payment/tripos/refund/txn?txnId=${transactionId}&tenantId=${tenantId}`,
        {
            customHeaders: {
                "Content-Type": "application/json",
            },
        }
    )

export const savedCardForTilled = (req) =>
    putRequest(`payment/attach/tilled/payment/customer`, req)

export const deleteSavedCard = (req) =>
    putRequest(`payment/detach/tilled/payment/customer`, req)
export const createTilledCustomer = (req) =>
    postRequest(`payment/create/tilled/customer`, req)
export const refundTilled = (req, tenantId, tilledAccId, customerId) =>
    postRequest(
        `payment/create/refund/${tenantId}/${tilledAccId}?customerId=${customerId}`,
        req
    )
export const refundPayrix = (req) =>
    postRequest(`payment/payrix/vt/refund/txn`, req)

export const customerSignature = (req) =>
    postRequest(`payment/tripos/get/customer/signature`, req)

export const voidPayment = (requestBody) =>
    postRequest("/order/v3/void/payment", requestBody)
