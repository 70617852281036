import axiosSetup from "./axiosSetup"

const { postRequest, getRequest, putRequest, delRequest } = axiosSetup(false)

export const getUserDetails = (requestBody) =>
    postRequest("/company/user/getUserById", requestBody)
export const getCustomers = (requestBody) =>
    getRequest(`/company/v2/getCustomersSummary?companyId=${requestBody}`)

// Individual costomer details
export const getCompanyById = (requestBody) =>
    postRequest(`/company/getCompanyById`, requestBody)

export const getCompanyUsers = (requestBody) =>
    postRequest(`/company/getCompanyUsers`, requestBody)
// get all payment information
export const getCompanyAllPaymentTerms = (requestBody) =>
    postRequest(`/company/getAllPaymentInformation`, requestBody)

//company taxation details
export const getCompanyTaxation = ({ companyId, isDefault = true }) =>
    getRequest(
        `company/get/taxation/tenant?companyId=${companyId}&isDefault=${isDefault}`
    )

export const getCustomerProjectTaxation = ({
    companyId,
    customerId,
    projectId,
    isDefault = true,
}) =>
    getRequest(
        `company/project/get/taxation?companyId=${companyId}&customerId=${customerId}&projectId=${projectId}&isDefault=${isDefault}`
    )

export const uploadDocument = (requestBody) =>
    postRequest("document/upload", requestBody, {
        customHeaders: {
            "Content-Type": "multipart/form-data",
        },
    })

export const getAttachedDocuments = (requestBody) =>
    postRequest("/document/getAllDocuments", requestBody)

export const editAttachment = (requestBody, id) =>
    putRequest(`document/update/id/${id}`, requestBody)

export const downloadAttachment = (id) => getRequest(`document/get/id/${id}`)

export const getDocumentByKey = (reqBody) =>
    postRequest(`/document/getByKey`, reqBody)

export const deleteAttachedDocument = (id) =>
    delRequest(`document/delete/id/${id}`)

export const printThermal = (thermalInvoiceUrlPost, requestBody) =>
    postRequest(thermalInvoiceUrlPost, requestBody)
export const printThermalTypeGet = (thermalInvoiceUrlPost) =>
    getRequest(thermalInvoiceUrlPost)

export const printA4 = (printA4Url) => getRequest(printA4Url)

export const printYardTicket = (printYardTicketUrl, type = "GET") => {
    if (type === "POST") {
        return postRequest(printYardTicketUrl)
    } else {
        return getRequest(printYardTicketUrl)
    }
}

export const getPaymentTransactions = (id) => {
    return getRequest(`/order/v2/getSOPaymentTransactions?orderId=${id}`)
}

// get deliveryTicketDetails

export const downloadExcel = (url) => getRequest(url)

export const downloadExcelPostRequest = (url) => postRequest(url)

export const getDeliveryTicketDetails = ({ orderId }) =>
    getRequest(`/order/getDeliveryReceiptById/${orderId}`)
export const getPaymentTerms = ({ paymentTermId }) =>
    postRequest(`company/get/paymentTerm?paymentTermId=${paymentTermId}`)

export const getRMTlist = (requestBody) =>
    postRequest(`/order/deliveryTicket/search`, requestBody)

export const getOrderByOrderIdForDeliveryReceipt = (requestBody) => {
    return postRequest(
        "/order/getOrderByOrderIdForDeliveryReceipt",
        requestBody
    )
}

// export const getProjectedInventory = (requestBody) =>
//     postRequest("/inventory/v2/projectedInventory", requestBody)

export const createOrderDeliveryTicket = (requestBody) =>
    postRequest(`/order/deliveryTicketList`, requestBody)

// get estimatesList
export const getEstimatesList = (requestBody) =>
    postRequest(`order/estimate/search`, requestBody)

export const deleteEstimatesList = (requestBody) =>
    postRequest(`order/estimate/search`, requestBody)
export const getVendors = ({ companyId, status, isOptimized = false }) =>
    getRequest(
        `/company/v2/getVendors?companyId=${companyId}&status=${status}&optimized=${isOptimized}`
    )

//payments
export const getPaymentInformation = (requestBody) =>
    postRequest(`/company/getPaymentInformation`, requestBody)
export const getMerchantDetails = (tenantId) =>
    getRequest(`/payment/get/merchant?tenantId=${tenantId}`)
export const savePaymentCustomerDetails = (customer) =>
    postRequest(`/payment/payrix/vt/create/customer`, customer)
export const savePaymentTransaction = (txnDetails) =>
    postRequest(`/payment/payrix/vt/save/transaction`, txnDetails)
export const createTokenizedTransaction = (txnDetails) =>
    postRequest(`/payment/payrix/vt/create/tokenize/txn`, txnDetails)
export const deletePayrixSavedCard = (txnDetails) =>
    postRequest(`/payment/payrix/vt/delete/token`, txnDetails)

export const getSavedCards = (tenantId) =>
    getRequest(`/payment/get/saved-cards?tenantId=${tenantId}`)
export const getSavedACHs = (tenantId) =>
    getRequest(`/payment/get/saved-ach?tenantId=${tenantId}`)

export const getSavedPaymentsDetailsByPlatformType = (
    tenantId,
    bbCustomerId,
    platformType
) =>
    getRequest(
        `/payment/get/tilled/customer?tenantId=${tenantId}&bbCustomerId=${bbCustomerId}&platformType=${platformType}`
    )

export const getCustomersList = (companyId, status) =>
    getRequest(
        `company/v2/getCustomers?companyId=${companyId}&status=${status}`
    )

// send Email

export const sendEmail = (sendEmailUrl) => getRequest(sendEmailUrl)

export const getLocations = (parentCompanyId) =>
    getRequest(`/company/locations?parentCompanyId=${parentCompanyId}`)

export const emailEstimate = ({ requestBody, url }) =>
    postRequest(url, requestBody)
