import "./App.scss"
import React from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import { Amplify } from "aws-amplify"
import awsExports from "./aws-exports"
import "@aws-amplify/ui-react/styles.css"
import { CssBaseline, ThemeProvider } from "@mui/material"
import AppRoutes from "./routes/Routes"
import { theme } from "./theme"
import { SnackbarProvider } from "notistack"
import CacheBuster from "react-cache-buster"
import pkg from "../package.json"
import CacheChecking from "./CacheChecking"

// Configure Amplify
Amplify.configure({
    Auth: {
        region: awsExports.REGION,
        userPoolId: awsExports.USER_POOL_ID,
        userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    },
})

function App() {
    // fix for hot reloading
    process.env.REACT_APP_FAST_REFRESH = false
    process.env.SKIP_PREFLIGHT_CHECK = true
    const isProduction = true

    return (
        <CacheBuster
            currentVersion={pkg.version}
            isEnabled={isProduction} //If false, the library is disabled.
            loadingComponent={<CacheChecking />} //If not pass, nothing appears at the time of new version check.
        >
            <SnackbarProvider maxSnack={5}>
                <Authenticator>
                    {({ signOut, user }) => (
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AppRoutes user={user} signOut={signOut} />
                        </ThemeProvider>
                    )}
                </Authenticator>
            </SnackbarProvider>
        </CacheBuster>
    )
}

export default App
