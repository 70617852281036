import React, { useEffect, useState } from "react"
import logo from "../../assets/img/logo.png"
import HoverDropdown from "../hover-dropdown/HoverDropdown"
import "./header.scss"
import { getUserDetails } from "../../utils/company"
import { clearAwsSession, getAwsSession } from "../../utils/awsSession"
import { Box, Button, Tooltip } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import AddProduct from "../AddProduct"
import { SimpleDialog } from "../SimpleDialog"
import { MenuItem, Select } from "@mui/material"
import { getTriposLanes } from "../../utils/payment"
import { enqueueSnackbar } from "notistack"
import MobileMenu from "./MobileMenu"

const ANGULAR_URL =
    process.env.REACT_APP_ANGULAR_URL || "https://qa1-angular.buildbooster.com"

let headerLinks = [
    {
        name: "Home",
        url: "/",
    },
    {
        name: "Inventory",
        url: "/inventory/stock-products",
        children: [
            { name: "Stocked Products", url: "/inventory/stock-products" },
            {
                name: "Non Stocked Products",
                url: "/inventory/non-stock-products",
            },
            {
                name: "Services",
                url: "/inventory/service-products",
            },
            { name: "Vendor Products", url: "/inventory/vendor-products" },
            { name: "Charges", url: "/inventory/charges" },
            { name: "Discounts", url: "/inventory/discounts" },
            {
                name: "Catalog Management",
                url: "/inventory/catalog-management",
            },
        ],
    },
    // {
    //     name: "Estimates",
    //     newTab: true,
    //     url: `${ANGULAR_URL}/BB/estimator/estimates`,
    // },
    {
        name: "Estimates",
        url: "/estimates",
    },
    {
        name: "Sales",
        url: "/sales/order",
        children: [
            { name: "Sales Orders", url: "/sales/order" },
            { name: "Bill of Lading", url: "/sales/bol" },
            { name: "Invoices", url: "/sales/invoices" },
            { name: "Customer Return Orders", url: "/sales/ro" },
            // { name: "Direct Return Orders", url: "/sales/create-direct" },
            {
                name: "Customers",
                newTab: true,
                url: `${ANGULAR_URL}/BB/sales/customers`,
            },
            // {
            //     name: "Customer Credit Wallet",
            //     url: "/sales/customer-credit-wallet",
            // },
            {
                name: "Customer Credit Memo",
                url: "/sales/customer-credit-memo",
            },
        ],
    },
    {
        name: "Receivables",
        url: "/receivables/statement",
        children: [
            { name: "Payments", url: "/receivables/payments" },
            {
                name: "Account Receivables",
                url: "/receivables/statement",
                children: [
                    {
                        name: "Receive Payments",
                        url: "/receivables/statement-accept-payment",
                    },
                    {
                        name: "Balance WriteOff",
                        url: "/receivables/balance-write-off",
                    },
                ],
            },
        ],
    },
    {
        name: "Purchases",
        url: "/purchases/order",
        children: [
            // { name: "Receive Materials", url: "/purchases/create-rmt" },
            { name: "Purchase Order", url: "/purchases/order" },
            // {
            //     name: "Received Material Tickets",
            //     url: "purchases/receive-ticket-list",
            // },
            { name: "Bills", url: "/purchases/bills" },
            {
                name: "Vendors",
                newTab: true,
                url: `${ANGULAR_URL}/BB/purchases/vendors`,
            },
        ],
    },
    {
        name: "Payables",
        url: "/payables/vendorwise",
        children: [
            { name: "Payouts", url: "/payables/payouts" },
            {
                name: "Account Payables",
                url: "/payables/vendorwise",
                children: [
                    {
                        name: "Log payout",
                        url: "/payables/payout",
                    },
                    {
                        name: "Vendor Balance WriteOff",
                        url: "/payables/balance-write-off",
                    },
                ],
            },
        ],
    },
    {
        name: "Accounting",
        url: "/accounting/add-journal",
        children: [
            { name: "Add Journal Entry", url: "/accounting/add-journal" },
            { name: "Accounts", url: "/accounting/accounts" },
            { name: "Locations", url: "/accounting/locations" },
            { name: "Cost Center", url: "/accounting/cost-center" },
            { name: "Currency", url: "/accounting/currency" },
            { name: "Company", url: "/accounting/company" },
            // { name: "Accounting Period", url: "/accounting/accounting-period" },
            { name: "Balance Sheet Report", url: "/accounting/balance-sheet" },
            {
                name: "Income Statement Report",
                url: "/accounting/income-statement",
            },
        ],
    },
    {
        name: "Reports",
        url: "/reports/payment-report",
        children: [
            { name: "Payment Reports", url: "/reports/payment-report" },
            {
                name: "Product Sales Reports",
                url: "/reports/product-sales-report",
            },
            {
                name: "Sales & Profit Reports",
                url: "/reports/sales-profit-report",
            },
            { name: "Inventory Reports", url: "/reports/inventory-report" },
            {
                name: "Sales & Profit Reports(Invoicewise)",
                url: "/reports/sales-profit-report-invoicewise",
            },
            {
                name: "Download Sales Tax Reports",
                url: "/reports/sales-tax-report",
            },
        ],
    },
    {
        name: "Settings",
        newTab: true,
        url: `${ANGULAR_URL}/BB/admin/settings`,
        children: [
            {
                name: "Company Setup",
                newTab: true,
                url: `${ANGULAR_URL}/BB/admin/settings`,
            },
            {
                name: "User & Roles",
                newTab: true,
                url: `${ANGULAR_URL}/BB/admin/users`,
            },
            // {
            //     name: "Merchant Setup",
            //     url: "/settings/merchant-setup",
            // },
            {
                name: "Notification",
                newTab: true,
                url: `${ANGULAR_URL}/BB/admin/notifications`,
            },
        ],
    },
]

function Header(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [openAddProductModal, setOpenAddProductModal] = React.useState(false)
    const session = JSON.parse(localStorage.getItem("userDetails"))
    const [companyId, setCompanyId] = useState()
    const [triposLanes, setTriposLanes] = useState([])
    const [showLaneSelector, setShowLaneSelector] = useState(false)
    const [selectedTriposLane, setSelectedTriposLane] = useState("")
    const signout = () => {
        props.signOut()
        clearAwsSession()
        // localStorage.removeItem('tenantId')
        // localStorage.setItem('userDetails', "{}")
        localStorage.removeItem("selected-laneId-shown-once")
        localStorage.removeItem("selected-laneId")
    }
    useEffect(() => {
        getAwsSession().then((session) => {
            const payload = session.getIdToken().payload

            getUserDetails({
                companyId: payload["custom:companyId"],
                userId: payload["custom:userId"],
            }).then(
                (userDetails) => {
                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify(userDetails.body.data[0])
                    )
                    localStorage.setItem(
                        "tenantId",
                        JSON.stringify(userDetails.body.data[0].companyId)
                    )
                    setCompanyId(userDetails.body.data[0].companyId)
                    if (!!userDetails.body.data[0]?.company?.parentCompanyId) {
                        if (
                            !headerLinks[1].children.find(
                                (val) => val.name === "Product Transfers"
                            )
                        ) {
                            headerLinks[1].children.push({
                                name: "Product Transfers",
                                url: "/inventory/product-transfer-list",
                            })
                        }
                    }

                    const roleList = userDetails?.body?.data[0]?.roleList || []
                    const userRole = roleList.find(
                        (o) =>
                            o.companyId === userDetails.body.data[0].companyId
                    )?.roleName
                    if (userRole !== "ADMIN") {
                        headerLinks = headerLinks.filter(
                            (obj) =>
                                obj.name !== "Reports" &&
                                obj.name !== "Accounting"
                        )
                    }
                },
                (failure) => {
                    console.log(failure)
                }
            )
        })
    }, [])

    useEffect(() => {
        companyId &&
            getTriposLanes(companyId)
                .then((resp) => {
                    setTriposLanes(resp.data)
                    if (
                        !localStorage.getItem("selected-laneId") &&
                        !localStorage.getItem("selected-laneId-shown-once")
                    )
                        setShowLaneSelector(!!resp.data.length)
                })
                .catch((error) => {
                    console.error(error)
                    enqueueSnackbar(
                        "Error while fetching card machine lanes!",
                        {
                            variant: "error",
                        }
                    )
                })
    }, [companyId])

    function handleLaneSelect(params) {
        localStorage.setItem("selected-laneId", selectedTriposLane)
        setShowLaneSelector(false)
    }

    function onLaneSelectClose() {
        localStorage.setItem("selected-laneId-shown-once", "yes")
        setShowLaneSelector(false)
    }

    useEffect(() => {
        let currentMenu
        for (let i = 0; i < headerLinks.length; i++) {
            const menu = headerLinks[i]
            if (menu?.children?.length) {
                const submenuList = menu.children
                for (let j = 0; j < submenuList.length; j++) {
                    const submenu = submenuList[j]
                    if (submenu?.children?.length) {
                        const submenuList2 = submenu.children
                        for (let k = 0; k < submenuList2.length; k++) {
                            const submenu2 = submenuList2[k]
                            if (location.pathname === submenu2.url) {
                                currentMenu = submenu2
                                break
                            }
                        }
                    }

                    if (currentMenu) {
                        break
                    } else {
                        if (location.pathname === submenu.url) {
                            currentMenu = submenu
                            break
                        }
                    }
                }
            }

            if (currentMenu) {
                break
            } else {
                if (location.pathname === menu.url) {
                    currentMenu = menu
                    break
                }
            }
        }
        if (currentMenu) {
            document.title = `BB | ${currentMenu.name}`
        }
    }, [headerLinks, location.pathname])

    return (
        <div className="bb-header">
            <div className="logo-container">
                <img src={logo} className="logo" alt="" />
            </div>
            <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
                {headerLinks.map((item, index) => (
                    <HoverDropdown key={index} menu={item} />
                ))}
            </Box>
            <div className="account signout-blk">
                <MobileMenu mobileMenuLinks={headerLinks} />
                <Tooltip title="Add Product">
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={() => setOpenAddProductModal(true)}
                    >
                        &nbsp; + Product &nbsp;
                    </Button>
                </Tooltip>
                <Tooltip title="Create Invoice">
                    <Button
                        size="medium"
                        variant="contained"
                        onClick={() => navigate("/sales/invoices/create")}
                    >
                        &nbsp; + Invoice &nbsp;
                    </Button>
                </Tooltip>
                <span className="link" onClick={signout}>
                    {" "}
                    Sign Out
                </span>
            </div>
            {openAddProductModal && (
                <AddProduct
                    companyId={session.companyId}
                    companyName={session.company.companyDisplayName}
                    userId={session.userId}
                    open={openAddProductModal}
                    handleDialogClose={() => {
                        setOpenAddProductModal(false)
                    }}
                />
            )}
            {!!showLaneSelector && props.headerType !== "loading" && (
                <SimpleDialog
                    open={!!showLaneSelector}
                    setOpen={setShowLaneSelector}
                    title={`Choose card machine`}
                    onConfirm={handleLaneSelect}
                    disableConfirmBtn={!selectedTriposLane}
                    onClose={onLaneSelectClose}
                >
                    <Select
                        size="small"
                        fullWidth
                        value={selectedTriposLane}
                        onChange={(event) =>
                            setSelectedTriposLane(event.target.value)
                        }
                        displayEmpty
                        inputProps={{}}
                    >
                        <MenuItem hidden={true} key={1} value={""}>
                            -Pick an option--
                        </MenuItem>
                        {triposLanes.map(({ laneId, description }) => (
                            <MenuItem key={laneId} value={laneId}>
                                {laneId + ": " + description}
                            </MenuItem>
                        ))}
                    </Select>
                </SimpleDialog>
            )}
        </div>
    )
}

export default Header
