// Routes.js
import React, { Suspense, lazy } from "react"
import {
    Route,
    Outlet,
    BrowserRouter as Router,
    Routes,
} from "react-router-dom"
import Header from "../components/header/Header"
import PageNotFound from "../pages/PageNotFound"
import Charges from "../pages/inventory/charges/Charges"
import Discounts from "../pages/inventory/discounts/Discounts"
import StockProductsFiltersProvider from "../contexts/stock-products-context"
import EstimateFiltersProvider from "../contexts/estimate-context"
import SOFiltersProvider from "../contexts/so-context"
import InvoicesFiltersProvider from "../contexts/invoices-context"
import BolFiltersProvider from "../contexts/bol-context"
import CustomerReturnFiltersProvider from "../contexts/customer-return-context"
import PurchaseOrderFiltersProvider from "../contexts/purchase-order-context"
import POBillsFiltersProvider from "../contexts/po-bills-context"
import ServicesFiltersProvider from "../contexts/services-context"

const RootLayout = lazy(() => import("../pages/Root"))

const ProductDetails = lazy(() =>
    import("../pages/inventory/stock-products/product-details/ProductDetails")
)

const ServiceProductDetails = lazy(() =>
    import("../pages/inventory/service-products/product-details/ProductDetails")
)

const AccountReceivablesCustomerwise = lazy(() =>
    import(
        "../pages/receivables/account-receivables/account-receivables-customerwise"
    )
)
const AccountReceivablesLocationwise = lazy(() =>
    import(
        "../pages/receivables/account-receivables/account-receivables-locationwise/AccountReceivablesLocationwise"
    )
)
const AccountReceivablesProjectwise = lazy(() =>
    import(
        "../pages/receivables/account-receivables/account-receivables-projectwise"
    )
)
const AccountReceivablesTransactionwise = lazy(() =>
    import(
        "../pages/receivables/account-receivables/account-receivables-transactionwise"
    )
)

const DashboardPage = lazy(() => import("../pages/dashboard/Dashboard"))

const OrderList = lazy(() => import("../pages/sales/order/List"))
const CreateOrder = lazy(() => import("../pages/sales/order/create"))
const ViewSalesOrder = lazy(() => import("../pages/sales/order/view"))
const EditSalesOrder = lazy(() => import("../pages/sales/order/edit"))

const Tickets = lazy(() => import("../pages/sales/bill-of-lading/List"))
const CreateBillOfLading = lazy(() =>
    import("../pages/sales/bill-of-lading/create")
)
const ViewBOL = lazy(() => import("../pages/sales/bill-of-lading/view"))
const EditBillOfLading = lazy(() =>
    import("../pages/sales/bill-of-lading/edit")
)
const MerchantSetup = lazy(() => import("../pages/settings/index"))

const InvoiceList = lazy(() => import("../pages/sales/invoice/List"))
const CreateInvoice = lazy(() => import("../pages/sales/invoice/create"))
const CreateInvoiceNew = lazy(() =>
    import("../pages/sales/invoice/invoice-return-create")
)
const ViewInvoice = lazy(() => import("../pages/sales/invoice/view"))

const ReturnOrder = lazy(() => import("../pages/sales/return-order/List"))
const CreateCustomerReturnOrder = lazy(() =>
    import("../pages/sales/return-order/create")
)
const CreateDirectReturnOrder = lazy(() =>
    import("../pages/sales/return-order/create-direct-ro")
)
const ViewCustomerReturnOrder = lazy(() =>
    import("../pages/sales/return-order/view")
)

const BillList = lazy(() => import("../pages/purchases/bill/List"))
const CreateBill = lazy(() => import("../pages/purchases/bill/create"))
const EditBill = lazy(() => import("../pages/purchases/bill/view-edit"))

const AccountPayablesByVendorWise = lazy(() =>
    import("../pages/payables/account-payables/AccountPayablesByVendorWise")
)
const AccountPayablesByLocationWise = lazy(() =>
    import(
        "../pages/payables/account-payables-locationwise/AccountPayablesLocationWise"
    )
)
const VendorLedger = lazy(() =>
    import("../pages/payables/vendor-ledger/VendorLedger")
)
const PayoutsList = lazy(() => import("../pages/payables/payouts/PayoutsList"))
const PayOut = lazy(() => import("../pages/payables/pay-out/PayOut"))
const PayOutNew = lazy(() => import("../pages/payables/pay-out-new/PayOut"))
const VendorBalanceWriteOff = lazy(() =>
    import("../pages/payables/balance-write-off/VendorBalanceWriteOff")
)
const EstimateList = lazy(() => import("../pages/estimate/list/EstimateList"))
const NewEstimate = lazy(() =>
    import("../pages/estimate/new-estimate/NewEstimate")
)

const PurchaseOrderList = lazy(() => import("../pages/purchases/order/List"))
const CreatePO = lazy(() => import("../pages/purchases/order/create"))
const EditPO = lazy(() => import("../pages/purchases/order/edit"))
const ViewPurchaseOrder = lazy(() => import("../pages/purchases/order/view"))

const InventoryReport = lazy(() =>
    import("../pages/reports/inventoryReport/index")
)

const StockProducts = lazy(() =>
    import("../pages/inventory/stock-products/list/StockProducts")
)

const EditStockProducts = lazy(() =>
    import(
        "../pages/inventory/stock-products/edit-stock-products/EditStockProducts"
    )
)

const EditVendorProducts = lazy(() =>
    import(
        "../pages/inventory/vendor-products/edit-vendor-products/EditVendorProducts"
    )
)

const NonStockProducts = lazy(() =>
    import("../pages/inventory/non-stock-products")
)

const ServiceProducts = lazy(() =>
    import("../pages/inventory/service-products")
)

const VendorProducts = lazy(() =>
    import("../pages/inventory/vendor-products/VendorProducts")
)

const CatalogManagement = lazy(() =>
    import("../pages/inventory/catalog-management/CatalogManagement")
)

const CategoryManagement = lazy(() =>
    import("../pages/inventory/category-management/CategoryManagement")
)

const InventorySettings = lazy(() =>
    import("../pages/inventory/inventory-settings")
)

const CustomerList = lazy(() =>
    import("../pages/sales/customers/List/CustomerList")
)
const CreateCustomer = lazy(() => import("../pages/sales/customers/create"))
const PaymentsList = lazy(() =>
    import("../pages/receivables/payments/PaymentsList")
)
const ReceivePayment = lazy(() =>
    import("../pages/receivables/account-receivables/receive-payment")
)
const ReceivePaymentNew = lazy(() =>
    import("../pages/receivables/account-receivables/receive-payment-new")
)
const RefundScreen = lazy(() =>
    import("../pages/receivables/account-receivables/refund")
)
const WalletRefundScreen = lazy(() =>
    import("../pages/receivables/account-receivables/wallet-refund")
)
const CustomerBalanceWriteOff = lazy(() =>
    import("../pages/receivables/account-receivables/balance-writeoff")
)
const PaymentTermsStatement = lazy(() =>
    import(
        "../pages/receivables/account-receivables/payment-terms-statement/PaymentTermsStatement"
    )
)
const StatementView = lazy(() =>
    import(
        "../pages/receivables/account-receivables/payment-terms-statement/statement-view/StatementView"
    )
)
const CustomerLedger = lazy(() =>
    import(
        "../pages/receivables/account-receivables/customer-ledger/CustomerLedger"
    )
)

const VendorsList = lazy(() =>
    import("../pages/purchases/vendors/List/VendorsList")
)

const PaymentReport = lazy(() => import("../pages/reports/paymentReport"))
const ProductSalesReport = lazy(() =>
    import("../pages/reports/productSalesReport/ProductSalesReport")
)
const PaymentReportMonthwise = lazy(() =>
    import(
        "../pages/reports/paymentReport/paymentReportMonthwise/PaymentReportMonthwise"
    )
)
const PaymentReportDaywise = lazy(() =>
    import(
        "../pages/reports/paymentReport/paymentReportDaywise/PaymentReportDaywise"
    )
)
const PaymentReportDay = lazy(() =>
    import("../pages/reports/paymentReport/paymentReportDay/PaymentReportDay")
)

const Accounting = lazy(() =>
    import("../pages/accounting/add-journal/AddJournal")
)

const TreeViewDimensionsList = lazy(() =>
    import("../pages/accounting/list/treeViewDimensions/TreeViewDimensionsList")
)
const DimensionsList = lazy(() =>
    import("../pages/accounting/list/dimensions/DimensionsList")
)
const AccountingPeriodList = lazy(() =>
    import("../pages/accounting/list/dimensions/AccountingPeriodList")
)
const AccountDetailsList = lazy(() =>
    import("../pages/accounting/list/AccountDetailsList")
)
const ReportsSheet = lazy(() =>
    import("../pages/accounting/reports/ReportsSheet")
)
const ProductTransferList = lazy(() =>
    import("../pages/inventory/product-transfers/List/ProductTransferList")
)
const CreateProductTransfer = lazy(() =>
    import("../pages/inventory/product-transfers/Create/CreateProductTransfer")
)
const ProductTransferView = lazy(() =>
    import("../pages/inventory/product-transfers/View/ProductTransferView")
)
const EditProductTransfer = lazy(() =>
    import("../pages/inventory/product-transfers/Edit/EditProductTransfer")
)
const SalesTaxReport = lazy(() =>
    import("../pages/reports/salesTaxReport/SalesTaxReport")
)
const CommitedCategoryProductReport = lazy(() =>
    import(
        "../pages/reports/inventoryReport/CommitedReport/CommitedCategoryProductReport/CommitedCategoryProductReport"
    )
)
const OnOrderCategoryProductReport = lazy(() =>
    import(
        "../pages/reports/inventoryReport/OnOrderReport/OnOrderCategoryProductReport/OnOrderCategoryProductReport"
    )
)
const InventoryValuationReportForProducts = lazy(() =>
    import(
        "../pages/reports/inventoryReport/InventoryValuationReport/InventoryValuationReportForProducts/InventoryValuationReportForProducts"
    )
)
const CreateQuote = lazy(() => import("../pages/estimate/create-quote"))
const EditEstimate = lazy(() => import("../pages/estimate/edit"))
const ViewQuote = lazy(() => import("../pages/estimate/view/ViewQuote"))
const SalesAndProfitReport = lazy(() =>
    import("../pages/reports/salesAndProfitReport/SalesAndProfitReport")
)
const DateRangewiseReportDay = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/DateRangewise/DateRangewiseReportDay/DateRangewiseReportDay"
    )
)
const DateRangewiseReportMonthly = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/DateRangewise/DateRangewiseReportMonthly/DateRangewiseReportMonthly"
    )
)
const DateRangewiseReportDaily = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/DateRangewise/DateRangewiseReportDaily/DateRangewiseReportDaily"
    )
)
const SalespersonwiseReportByCustomers = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/Salespersonwise/SalespersonwiseReportByCustomers/SalespersonwiseReportByCustomers"
    )
)
const SalespersonwiseReportByProjects = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/Salespersonwise/SalespersonwiseReportByProjects/SalespersonwiseReportByProjects"
    )
)
const SalespersonwiseReportByInvoices = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/Salespersonwise/SalespersonwiseReportByInvoices/SalespersonwiseReportByInvoices"
    )
)
const CustomerwiseReportByProjects = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/Customerwise/customerwiseReportByProjects/CustomerwiseReportByProjects"
    )
)
const CustomerwiseReportByInvoices = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/Customerwise/customerwiseReportByInvoices/CustomerwiseReportByInvoices"
    )
)
const SalesProfitReportInvoicewise = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/SalesProfitReportInvoicewise"
    )
)
const DateRangeInvoicewiseMonthlyReport = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/dateRangeInvoicewise/dateRangeInvoicewiseMonthlyReport/DateRangeInvoicewiseMonthlyReport"
    )
)
const DateRangeInvoicewiseDailyReport = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/dateRangeInvoicewise/dateRangeInvoicewiseDailyReport/DateRangeInvoicewiseDailyReport"
    )
)
const DateRangeInvoicewiseDayReport = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/dateRangeInvoicewise/dateRangeInvoicewiseDayReport/DateRangeInvoicewiseDayReport"
    )
)
const CustomerwiseReportProjects = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/customerwiseReport/customerwiseReportProjects/CustomerwiseReportProjects"
    )
)
const CustomerwiseReportProjectsInvoices = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/customerwiseReport/customerwiseReportProjectsInvoices/CustomerwiseReportProjectsInvoices"
    )
)
const SalespersonInvoicewiseReportByCustomers = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/salespersonInvoicewise/salespersonInvoicewiseReportByCustomers/SalespersonInvoicewiseReportByCustomers"
    )
)
const SalespersonInvoicewiseCustomersProjects = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/salespersonInvoicewise/salespersonInvoicewiseReportByProjects/SalespersonInvoicewiseCustomersProjects"
    )
)
const SalespersonInvoicewiseReportByInvoices = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/salespersonInvoicewise/salespersonInvoicewiseReportInvoices/SalespersonInvoicewiseReportByInvoices"
    )
)
const CommittedQtyTransactionList = lazy(() =>
    import(
        "../pages/reports/inventoryReport/CommitedReport/CommittedQtyTransactionList"
    )
)
const OnOrderQuantityTransactionList = lazy(() =>
    import(
        "../pages/reports/inventoryReport/OnOrderReport/OnOrderQtyTransactionList"
    )
)
const OutsideSalespersonInvoicewiseReportByCustomers = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/OutsideSalespersonInvoicewise/OutsiseSalespersonInvoicewiseReportByCustomers/OutsideSalespersonInvoicewiseReportByCustomers"
    )
)
const OutsideSalespersonInvoicewiseReportByProjects = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/OutsideSalespersonInvoicewise/OutsideSalespersonInvoicewiseReportByProjects/OutsideSalespersonInvoicewiseReportByProjects"
    )
)
const OutsideSalespersonInvoicewiseReportByInvoices = lazy(() =>
    import(
        "../pages/reports/salesProfitReportInvoicewise/OutsideSalespersonInvoicewise/OutsideSalespersonInvoicewiseReportByInvoices/OutsideSalespersonInvoicewiseReportByInvoices"
    )
)
const OutsideSalespersonwiseReportByCustomers = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/OutsideSalespersonwise/OutsiseSalespersonwiseReportByCustomers/OutsideSalespersonwiseReportByCustomers"
    )
)
const OutsideSalespersonwiseReportByProjects = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/OutsideSalespersonwise/OutsideSalespersonwiseReportByProjects/OutsideSalespersonwiseReportByProjects"
    )
)
const OutsideSalespersonwiseReportByInvoices = lazy(() =>
    import(
        "../pages/reports/salesAndProfitReport/OutsideSalespersonwise/OutsideSalespersonwiseReportByInvoices/OutsideSalespersonwiseReportByInvoices"
    )
)

const CustomerCreditMemo = lazy(() =>
    import("../pages/sales/customer-credit-memo")
)

const AppRoutes = ({ user, signOut }) => (
    <Router>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route
                    path="/"
                    element={<RootLayout user={user} signOut={signOut} />}
                >
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="payables" element={<Outlet />}>
                        <Route path="payouts" element={<Outlet />}>
                            <Route
                                index
                                element={<PayoutsList title={"Payouts"} />}
                            />
                        </Route>
                        <Route path="vendorwise" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <AccountPayablesByVendorWise
                                        title={"Account Payables"}
                                    />
                                }
                            />
                        </Route>
                        <Route path="locationwise" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <AccountPayablesByLocationWise
                                        title={"Account Payables"}
                                    />
                                }
                            />
                        </Route>
                        <Route path="vendor-ledger" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <VendorLedger title={"Vendor Ledger"} />
                                }
                            />
                        </Route>
                        {/* <Route path="payout" element={<Outlet />}>
                            <Route
                                index
                                element={<PayOut title={"Payout"} />}
                            />
                        </Route> */}
                        <Route path="payout" element={<Outlet />}>
                            <Route
                                index
                                element={<PayOutNew title={"Payout"} />}
                            />
                        </Route>
                        <Route
                            path="balance-write-off"
                            element={
                                <VendorBalanceWriteOff
                                    title={"Vendor Balance Write-off"}
                                />
                            }
                        />
                    </Route>
                    <Route path="purchases" element={<Outlet />}>
                        <Route path="order" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <PurchaseOrderFiltersProvider>
                                        <PurchaseOrderList />
                                    </PurchaseOrderFiltersProvider>
                                }
                            />
                            <Route path="create" element={<CreatePO />} />
                            <Route
                                path="edit"
                                element={
                                    <PurchaseOrderFiltersProvider>
                                        <EditPO />
                                    </PurchaseOrderFiltersProvider>
                                }
                            />
                            <Route
                                path="view"
                                element={
                                    <PurchaseOrderFiltersProvider>
                                        <ViewPurchaseOrder />
                                    </PurchaseOrderFiltersProvider>
                                }
                            />
                        </Route>
                        <Route path="bills" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <POBillsFiltersProvider>
                                        <BillList />
                                    </POBillsFiltersProvider>
                                }
                            />
                            <Route
                                path="create"
                                element={<CreateBill title="Create Bill" />}
                            />
                            <Route
                                path="edit"
                                element={
                                    <CreateBill title="Create Bill" isEdit />
                                }
                            />
                            <Route
                                path="view-bill"
                                element={
                                    <POBillsFiltersProvider>
                                        <EditBill title="Edit Bill" />
                                    </POBillsFiltersProvider>
                                }
                            />
                        </Route>
                        <Route
                            path="/purchases/vendors"
                            element={<VendorsList title={"Vendor"} />}
                        />
                    </Route>

                    <Route path="sales" element={<Outlet />}>
                        <Route path="order" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <SOFiltersProvider>
                                        <OrderList />
                                    </SOFiltersProvider>
                                }
                            />
                            <Route path="create" element={<CreateOrder />} />
                            <Route
                                path="edit"
                                element={
                                    <SOFiltersProvider>
                                        <EditSalesOrder />
                                    </SOFiltersProvider>
                                }
                            />
                            <Route
                                path="view"
                                element={
                                    <SOFiltersProvider>
                                        <ViewSalesOrder />
                                    </SOFiltersProvider>
                                }
                            />
                        </Route>
                        <Route path="bol" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <BolFiltersProvider>
                                        <Tickets />
                                    </BolFiltersProvider>
                                }
                            />
                            <Route
                                path="create"
                                element={<CreateBillOfLading />}
                            />
                            <Route
                                path="edit"
                                element={
                                    <BolFiltersProvider>
                                        <EditBillOfLading />
                                    </BolFiltersProvider>
                                }
                            />
                            <Route
                                path="view"
                                element={
                                    <BolFiltersProvider>
                                        <ViewBOL />
                                    </BolFiltersProvider>
                                }
                            />
                        </Route>
                        <Route path="invoices" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <InvoicesFiltersProvider>
                                        <InvoiceList />
                                    </InvoicesFiltersProvider>
                                }
                            />
                            {/* <Route path="create" element={<CreateInvoice />} /> */}
                            <Route
                                path="create"
                                element={<CreateInvoiceNew />}
                            />
                            <Route
                                path="view"
                                element={
                                    <InvoicesFiltersProvider>
                                        <ViewInvoice />
                                    </InvoicesFiltersProvider>
                                }
                            />
                        </Route>

                        <Route path="ro" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <CustomerReturnFiltersProvider>
                                        <ReturnOrder />
                                    </CustomerReturnFiltersProvider>
                                }
                            />
                            <Route
                                path="create"
                                element={
                                    <CreateCustomerReturnOrder title="Create Return Order" />
                                }
                            />
                            <Route
                                path="create-direct-ro"
                                element={
                                    <CreateDirectReturnOrder title="Create Direct Return Order" />
                                }
                            />
                            <Route
                                path="view"
                                element={
                                    <CustomerReturnFiltersProvider>
                                        <ViewCustomerReturnOrder />
                                    </CustomerReturnFiltersProvider>
                                }
                            />
                        </Route>
                        <Route
                            path="customer-list"
                            element={<CustomerList title="Customer" />}
                        />
                        <Route
                            path="customer/create"
                            element={<CreateCustomer />}
                        />
                        <Route
                            path="customer-credit-memo"
                            element={
                                <CustomerCreditMemo title="Customer Credit Memo Management" />
                            }
                        />
                    </Route>
                    <Route path="receivables" element={<Outlet />}>
                        <Route path="payments" element={<Outlet />}>
                            <Route
                                index
                                element={<PaymentsList title={"Payments"} />}
                            />
                        </Route>
                        <Route path="statement" element={<Outlet />}>
                            <Route
                                index
                                element={
                                    <AccountReceivablesCustomerwise
                                        title={"Account Receivables"}
                                    />
                                }
                            />
                        </Route>
                        <Route
                            path="statement-locationwise"
                            element={
                                <AccountReceivablesLocationwise
                                    title={"Account Receivables"}
                                />
                            }
                        />
                        <Route
                            path="statement-projectwise"
                            element={
                                <AccountReceivablesProjectwise
                                    title={"Account Receivables"}
                                />
                            }
                        />
                        <Route
                            path="statement-transactionwise"
                            element={
                                <AccountReceivablesTransactionwise
                                    title={"Account Receivables"}
                                />
                            }
                        />
                        {/* <Route
                            path="statement-accept-payment"
                            element={
                                <ReceivePayment title={"Receive Payment"} />
                            }
                        /> */}
                        <Route
                            path="statement-accept-payment"
                            element={<ReceivePaymentNew />}
                        />
                        <Route
                            path="refund"
                            element={<RefundScreen title={"Refund Options"} />}
                        />
                        <Route
                            path="wallet-refund"
                            element={
                                <WalletRefundScreen title={"Refund Options"} />
                            }
                        />
                        <Route
                            path="balance-write-off"
                            element={
                                <CustomerBalanceWriteOff
                                    title={"Customer Balance Write-off"}
                                />
                            }
                        />
                        <Route
                            path="payment-term-statement"
                            element={
                                <PaymentTermsStatement title={"Statements"} />
                            }
                        />
                        <Route
                            path="payment-statement-view"
                            element={<StatementView title={"Statements"} />}
                        />
                        <Route
                            path="customer-ledger"
                            element={
                                <CustomerLedger title={"Customer Ledger"} />
                            }
                        />
                    </Route>

                    <Route path="estimates" element={<Outlet />}>
                        <Route
                            index
                            element={
                                <EstimateFiltersProvider>
                                    <EstimateList />
                                </EstimateFiltersProvider>
                            }
                        />
                        <Route
                            path="new-estimates"
                            element={<NewEstimate />}
                        ></Route>
                        <Route
                            path="create-quote"
                            element={<CreateQuote />}
                        ></Route>
                        <Route
                            path="edit"
                            element={
                                <EstimateFiltersProvider>
                                    <EditEstimate />
                                </EstimateFiltersProvider>
                            }
                        ></Route>
                        <Route
                            path="view-quote"
                            element={
                                <EstimateFiltersProvider>
                                    <ViewQuote />
                                </EstimateFiltersProvider>
                            }
                        ></Route>
                    </Route>

                    <Route path="reports" element={<Outlet />}>
                        <Route
                            path="payment-report"
                            element={<PaymentReport title={"Payment Report"} />}
                        ></Route>
                        <Route
                            path="payment-report/monthly"
                            element={
                                <PaymentReportMonthwise
                                    title={"Payment Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="payment-report/daily"
                            element={
                                <PaymentReportDaywise
                                    title={"Payment Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="payment-report/day"
                            element={
                                <PaymentReportDay title={"Payment Report"} />
                            }
                        ></Route>
                        <Route
                            path="inventory-report"
                            element={<InventoryReport />}
                        ></Route>
                        <Route
                            path="inventory-report/committed-product-report"
                            element={
                                <CommitedCategoryProductReport
                                    title={"Committed Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="inventory-report/committed-list"
                            element={<CommittedQtyTransactionList />}
                        ></Route>
                        <Route
                            path="inventory-report/onOrder-product-report"
                            element={
                                <OnOrderCategoryProductReport
                                    title={"On-Order Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="inventory-report/onOrder-list"
                            element={<OnOrderQuantityTransactionList />}
                        ></Route>
                        <Route
                            path="inventory-report/inventory-valuation-report-byProduct"
                            element={
                                <InventoryValuationReportForProducts
                                    title={"Inventory Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="product-sales-report"
                            element={
                                <ProductSalesReport
                                    title={"Product Sales Report"}
                                />
                            }
                        ></Route>
                        <Route
                            path="sales-tax-report"
                            element={<SalesTaxReport />}
                        ></Route>
                        <Route
                            path="sales-profit-report"
                            element={
                                <SalesAndProfitReport title="Sales & Profit Report" />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report/day"
                            element={<DateRangewiseReportDay />}
                        ></Route>
                        <Route
                            path="sales-profit-report/monthly"
                            element={<DateRangewiseReportMonthly />}
                        ></Route>
                        <Route
                            path="sales-profit-report/daily"
                            element={<DateRangewiseReportDaily />}
                        ></Route>
                        <Route
                            path="sales-profit-report/salesperson/customers"
                            element={<SalespersonwiseReportByCustomers />}
                        ></Route>
                        <Route
                            path="sales-profit-report/salesperson/customers-projects"
                            element={<SalespersonwiseReportByProjects />}
                        ></Route>
                        <Route
                            path="sales-profit-report/salesperson/customer/project"
                            element={<SalespersonwiseReportByInvoices />}
                        ></Route>
                        <Route
                            path="sales-profit-report/outside-salesperson/customers"
                            element={
                                <OutsideSalespersonwiseReportByCustomers />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report/outside-salesperson/customers-projects"
                            element={<OutsideSalespersonwiseReportByProjects />}
                        ></Route>
                        <Route
                            path="sales-profit-report/outside-salesperson/customer/project"
                            element={<OutsideSalespersonwiseReportByInvoices />}
                        ></Route>
                        <Route
                            path="sales-profit-report/customer-projects"
                            element={<CustomerwiseReportByProjects />}
                        ></Route>
                        <Route
                            path="sales-profit-report/customer/project"
                            element={<CustomerwiseReportByInvoices />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise"
                            element={
                                <SalesProfitReportInvoicewise
                                    title={"Sales & Profit Report(Invoicewise)"}
                                />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/monthly"
                            element={<DateRangeInvoicewiseMonthlyReport />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/daily"
                            element={<DateRangeInvoicewiseDailyReport />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/day"
                            element={<DateRangeInvoicewiseDayReport />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/customer-projects"
                            element={<CustomerwiseReportProjects />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/project"
                            element={<CustomerwiseReportProjectsInvoices />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/salesperson/customers"
                            element={
                                <SalespersonInvoicewiseReportByCustomers />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/salesperson/customers-projects"
                            element={
                                <SalespersonInvoicewiseCustomersProjects />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/salesperson/customer/project"
                            element={<SalespersonInvoicewiseReportByInvoices />}
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/outside-salesperson/customers"
                            element={
                                <OutsideSalespersonInvoicewiseReportByCustomers />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/outside-salesperson/customers-projects"
                            element={
                                <OutsideSalespersonInvoicewiseReportByProjects />
                            }
                        ></Route>
                        <Route
                            path="sales-profit-report-invoicewise/outside-salesperson/customer/project"
                            element={
                                <OutsideSalespersonInvoicewiseReportByInvoices />
                            }
                        ></Route>
                    </Route>

                    <Route path="inventory" element={<Outlet />}>
                        <Route
                            index
                            path="stock-products"
                            element={
                                <StockProductsFiltersProvider>
                                    <StockProducts />
                                </StockProductsFiltersProvider>
                            }
                        ></Route>

                        <Route
                            index
                            path="edit-stock-products"
                            element={
                                <StockProductsFiltersProvider>
                                    <EditStockProducts />
                                </StockProductsFiltersProvider>
                            }
                        ></Route>

                        <Route
                            index
                            path="edit-vendor-products"
                            element={<EditVendorProducts />}
                        ></Route>

                        <Route
                            index
                            path="product-details"
                            element={
                                <StockProductsFiltersProvider>
                                    <ServicesFiltersProvider>
                                        <ProductDetails />
                                    </ServicesFiltersProvider>
                                </StockProductsFiltersProvider>
                            }
                        ></Route>

                        <Route
                            index
                            path="service-product-details"
                            element={<ServiceProductDetails />}
                        ></Route>

                        <Route
                            index
                            path="non-stock-products"
                            element={<NonStockProducts />}
                        ></Route>

                        <Route
                            index
                            path="service-products"
                            element={
                                <ServicesFiltersProvider>
                                    <ServiceProducts />
                                </ServicesFiltersProvider>
                            }
                        ></Route>

                        <Route
                            path="vendor-products"
                            element={<VendorProducts />}
                        ></Route>

                        <Route
                            index
                            path="charges"
                            element={<Charges />}
                        ></Route>

                        <Route
                            index
                            path="discounts"
                            element={<Discounts />}
                        ></Route>

                        <Route
                            index
                            path="catalog-management"
                            element={<CatalogManagement />}
                        ></Route>

                        <Route
                            path="vendor-catalog-management"
                            element={<CatalogManagement />}
                        ></Route>

                        <Route
                            path="category-management"
                            element={<CategoryManagement />}
                        ></Route>

                        <Route
                            path="inventory-settings"
                            element={<InventorySettings />}
                        ></Route>
                        <Route
                            path="product-transfer-list"
                            element={
                                <ProductTransferList
                                    title={"Product Transfers"}
                                />
                            }
                        ></Route>
                        <Route
                            path="product-transfer-create"
                            element={
                                <CreateProductTransfer
                                    title={"Create Transfer"}
                                />
                            }
                        ></Route>
                        <Route
                            path="product-transfer-view"
                            element={<ProductTransferView />}
                        ></Route>
                        <Route
                            path="product-transfer-edit"
                            element={<EditProductTransfer />}
                        ></Route>
                    </Route>

                    <Route path="accounting" element={<Outlet />}>
                        <Route
                            index
                            path="add-journal"
                            element={<Accounting />}
                        ></Route>
                        <Route
                            index
                            path="accounts"
                            element={
                                <TreeViewDimensionsList dimensionType="account" />
                            }
                        ></Route>
                        <Route
                            index
                            path="locations"
                            element={
                                <TreeViewDimensionsList dimensionType="location" />
                            }
                        ></Route>
                        <Route
                            index
                            path="company"
                            element={<DimensionsList dimensionType="company" />}
                        ></Route>
                        <Route
                            index
                            path="currency"
                            element={
                                <DimensionsList dimensionType="currency" />
                            }
                        ></Route>
                        <Route
                            index
                            path="cost-center"
                            element={
                                <DimensionsList dimensionType="cost-center" />
                            }
                        ></Route>
                        <Route
                            index
                            path="accounting-period"
                            element={<AccountingPeriodList />}
                        ></Route>
                        <Route
                            index
                            path="accounts/details"
                            element={<AccountDetailsList />}
                        ></Route>
                        <Route
                            index
                            path="balance-sheet"
                            element={<ReportsSheet />}
                        ></Route>
                        <Route
                            index
                            path="income-statement"
                            element={
                                <ReportsSheet
                                    title="Income Statement"
                                    type="income-statement"
                                />
                            }
                        ></Route>
                    </Route>
                    <Route path="settings" element={<Outlet />}>
                        <Route index element={<Outlet />} />
                        <Route
                            path="merchant-setup"
                            element={<MerchantSetup />}
                        />
                    </Route>
                    <Route path="*" element={<PageNotFound />} />

                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                </Route>
            </Routes>
        </Suspense>
    </Router>
)

function Loading(props) {
    return (
        <div className="App">
            <Header signOut={props.signOut} headerType="loading" />
            <div className="main-container">
                <center>Loading... </center>
            </div>
        </div>
    )
}

export default AppRoutes
