import { CancelOutlined } from "@mui/icons-material"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material"
import DebouncedButton from "./DebouncedButton"

export function SimpleDialog({
    setOpen,
    open,
    children = null,
    title,
    onConfirm,
    hideActions,
    maxWidth,
    disableConfirmBtn = false,
    hideCloseIcon,
    showDividers,
    yesLabel = "Yes",
    cancelLabel = "Cancel",
    hideCancelButton,
    onClose,
}) {
    const closeDialog = !onClose ? () => setOpen(false) : onClose
    return (
        <Dialog onClose={closeDialog} open={open} fullWidth maxWidth={maxWidth}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {!hideCloseIcon && (
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CancelOutlined />
                </IconButton>
            )}

            <DialogContent
                dividers={showDividers}
                sx={{
                    boxShadow: "inset 0px -5px 6px 0px #dedede",
                }}
            >
                {children}
            </DialogContent>
            {hideActions ? null : (
                <DialogActions sx={{ px: 1 }}>
                    {!hideCancelButton && (
                        <Button
                            size="large"
                            onClick={closeDialog}
                            sx={{ px: 2 }}
                        >
                            {cancelLabel}
                        </Button>
                    )}
                    {/* <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={onConfirm}
                        disabled={disableConfirmBtn}
                        sx={{ px: 6 }}
                    >
                        {yesLabel}
                    </Button> */}
                    <DebouncedButton
                        size="large"
                        sx={{ px: 6 }}
                        variant="contained"
                        color="primary"
                        delay={3000}
                        label={yesLabel}
                        onClick={onConfirm}
                        disabled={disableConfirmBtn}
                    />
                </DialogActions>
            )}
        </Dialog>
    )
}
